import React from "react"
import {graphql} from "gatsby"
import {MDXProvider} from "@mdx-js/react"
import {MDXRenderer} from "gatsby-plugin-mdx"
import Seo from "../composants/gabarits/seo";
import BaseGabarit from "./modèles/base";
import styled from "styled-components";

const StyledLayout = styled(BaseGabarit)`
    main {
        margin-top: max(15%, 200px);
        
        & > * > * + * {
            margin-top: 160px;
        }
        
        & > * > *:first-child + * {
            margin-top: 20%;
        }
    }
    
    > div > div:first-child {
        height: 100vw !important;
        min-height: 490px !important;

        @media screen and (min-width: 800px) {
            height: 100vh !important;
        }
        
        img {
          filter: brightness(60%);
        }
    }
`

export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...ListePageContent
        }
    }
`

export default function IndexGabarit({data: {page: {frontmatter, body}}}) {
    return (
        <StyledLayout banner={frontmatter.banner}>
            <Seo title={frontmatter.title}/>
            <MDXProvider>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
        </StyledLayout>
    )
}
